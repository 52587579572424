import { LabelBox, Loader } from '@fdha/web-ui-library';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import React, { useMemo } from 'react';
import { useGetDietPlanQuery } from '@fdha/graphql-api-foodops';

import { BasePage, PreviewPaper } from '../../../../components';

import { Week, buildWeeks, convertDietPlanToUIFormat } from './util';

const DietPlanPreview = () => {
  const { dietPlanId } = useParams();
  const { data, loading } = useGetDietPlanQuery({
    variables: { id: dietPlanId || '' },
    fetchPolicy: 'cache-and-network',
  });

  const dietPlan = data?.dietPlanByIdV2;

  const dietPlanFormatted = useMemo(() => {
    if (!dietPlan) {
      return;
    }

    return convertDietPlanToUIFormat(dietPlan);
  }, [dietPlan]);

  const renderWeeks = () => {
    if (dietPlanFormatted) {
      return buildWeeks(dietPlanFormatted.menusBySequenceNumber).map(
        (week: Week, index: number) => (
          <Grid container key={index}>
            <LabelBox
              size={6}
              label={week.firstDelivery.label}
              value={week.firstDelivery.menu?.name}
            />
            <LabelBox
              size={6}
              label={week.secondDelivery.label}
              value={week.secondDelivery.menu?.name}
            />
          </Grid>
        )
      );
    }
  };

  const renderDietPlanInfo = (
    title?: string,
    value?: string | JSX.Element[]
  ) => {
    return (
      <PreviewPaper>
        {title && (
          <>
            <Typography padding={2} fontWeight="500">
              {title}
            </Typography>
            <Divider />
          </>
        )}
        {React.isValidElement(value) ? (
          <Box pl={2} pt={2}>
            {value}
          </Box>
        ) : (
          <Typography padding={2}>{value || '-'}</Typography>
        )}
      </PreviewPaper>
    );
  };

  return (
    <BasePage>
      <BasePage.BackButton to="/create/diet-plan" />
      {loading ? (
        <Loader />
      ) : (
        <>
          {renderDietPlanInfo('Diet Plan Name', dietPlanFormatted?.name)}
          {renderDietPlanInfo(
            'Quick Ship Menu',
            dietPlanFormatted?.quickShipMenu?.name
          )}
          {renderDietPlanInfo('Week Menus', renderWeeks())}
        </>
      )}
    </BasePage>
  );
};

export default DietPlanPreview;
