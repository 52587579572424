import {
  Button,
  TextField,
  Autocomplete,
  useSnackbar,
  Loader,
  Icon,
  requiredMessage,
  DatePicker,
  addNotInPastDateValidation,
} from '@fdha/web-ui-library';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  useTheme,
} from '@mui/material';
import { FormikErrors, useFormik } from 'formik';
import { GraphQLError } from 'graphql';
import React, { useMemo } from 'react';
import * as Yup from 'yup';
import { useFeatureFlag } from '@fdha/common-hooks';
import {
  DietPlanDeliveryMenuInput,
  DietPlanInputV2,
  DietPlanUpdateInputV2,
  MenuType,
  useAddDietPlanMutation,
  useGetDietPlanQuery,
  useListMenusQuery,
  useUpdateDietPlanMutation,
  WeekDay,
} from '@fdha/graphql-api-foodops';
import { isSunday, isValid, nextSunday, startOfDay } from 'date-fns';

import { getOptions, getError as getErrorHelper } from '../../../../utils';

import {
  MenusBySequenceNumber,
  Week,
  buildWeeks,
  convertDietPlanToUIFormat,
} from './util';

const NUMBER_OF_RESULTS = 10000;

const getInitialMenus = (
  isWeekManagementEnabled: boolean
): MenusBySequenceNumber => {
  if (isWeekManagementEnabled) {
    return { 1: null, 2: null };
  }
  return { 1: null, 2: null, 3: null, 4: null };
};

const initialDate: Date = nextSunday(new Date());

interface DietPlanSchema {
  name: string;
  initialDate: Date | null;
  menus: MenusBySequenceNumber;
  quickShipMenu: string;
}

interface AddOrEditDietPlanProps {
  dietPlanId?: string;
  onSuccess?: () => void;
  onCancel?: () => void;
}

const AddOrEditDietPlan: React.FC<AddOrEditDietPlanProps> = ({
  dietPlanId,
  onSuccess,
  onCancel,
}) => {
  const { isFeatureEnabled } = useFeatureFlag();
  const showWeekManagement = isFeatureEnabled('show_diet_plan_week_management');

  const { showSnackbar } = useSnackbar();
  const theme = useTheme();

  const [addDietPlan] = useAddDietPlanMutation();
  const [updateDietPlan] = useUpdateDietPlanMutation();

  const { data: dietPlanData, loading: loadingDietPlans } = useGetDietPlanQuery(
    {
      variables: { id: dietPlanId || '' },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-only',
      skip: !dietPlanId,
    }
  );

  const { data: menusData, loading: menusLoading } = useListMenusQuery({
    variables: { first: NUMBER_OF_RESULTS },
    fetchPolicy: 'cache-and-network',
  });

  const dietPlan = useMemo(() => {
    if (dietPlanData?.dietPlanByIdV2) {
      return convertDietPlanToUIFormat(dietPlanData?.dietPlanByIdV2);
    }
  }, [dietPlanData]);

  const isEditMode = !!dietPlanId;

  const weekDayValidation = Yup.date()
    .nullable()
    .test('is-sunday', 'The date must be a Sunday', (value) => {
      if (!value) {
        return true;
      }
      return isValid(value) && isSunday(value);
    })
    .typeError('Please insert a valid date');

  const initialDateValidation = isEditMode
    ? weekDayValidation
    : addNotInPastDateValidation(undefined, false).concat(weekDayValidation);

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required(requiredMessage),
    initialDate: initialDateValidation.required(requiredMessage),
  });

  const initialValues: DietPlanSchema = {
    name: dietPlan?.name || '',
    initialDate: dietPlan?.initialDate ?? initialDate,
    menus:
      dietPlan?.menusBySequenceNumber || getInitialMenus(showWeekManagement),
    quickShipMenu: dietPlan?.quickShipMenu?.id || '',
  };

  const onSubmit = async (values: DietPlanSchema) => {
    const menus: DietPlanDeliveryMenuInput[] = Object.keys(values.menus).map(
      (key) => {
        const sequenceNumber = Number(key);
        const isFirstDelivery = sequenceNumber % 2 !== 0;
        const week = weeks.find(
          (week) =>
            week.firstDelivery.sequenceNumber === sequenceNumber ||
            week.secondDelivery.sequenceNumber === sequenceNumber
        );
        const weekLabel = isFirstDelivery
          ? week?.firstDelivery.label
          : week?.secondDelivery.label;

        return {
          name: weekLabel || '',
          sequenceNumber: sequenceNumber,
          deliveryDayOfWeek: isFirstDelivery ? WeekDay.Tue : WeekDay.Fri,
          menuId:
            values.menus[key as unknown as keyof MenusBySequenceNumber]?.id ||
            '',
          isInScheduler: true,
          type: MenuType.Regular,
        };
      }
    );

    if (values.quickShipMenu) {
      menus.push({
        name: 'Quick Ship',
        menuId: values.quickShipMenu,
        isInScheduler: false,
        type: MenuType.QuickShip,
      });
    }

    try {
      if (isEditMode) {
        const updatePayload: DietPlanUpdateInputV2 = {
          name: values.name,
          menus,
        };

        await updateDietPlan({
          variables: { dietPlanId: dietPlanId || '', input: updatePayload },
        });
      } else {
        if (!values.initialDate) {
          throw new Error('Initial date is required');
        }

        const addPayload: DietPlanInputV2 = {
          name: values.name,
          initialDate: startOfDay(values.initialDate).toISOString(),
          menus,
        };

        await addDietPlan({ variables: { input: addPayload } });
      }
      const action = isEditMode ? 'updated' : 'created';
      const message = `Diet Plan ${action} with success`;
      showSnackbar({
        message,
        severity: 'success',
      });
      onSuccess && onSuccess();
    } catch (e: any) {
      const handledError = e?.graphQLErrors?.find(
        (error: GraphQLError) => error.extensions?.code === 'BAD_USER_INPUT'
      );
      const action = isEditMode ? 'update' : 'create';
      const message = handledError?.message || `Error to ${action} Diet Plan`;
      showSnackbar({
        message,
        severity: 'error',
      });
    }
  };

  const onValidate = (values: DietPlanSchema) => {
    const errors: FormikErrors<DietPlanSchema> = {};

    Object.keys(values.menus).forEach((key) => {
      if (!values.menus[key as unknown as keyof MenusBySequenceNumber]?.id) {
        errors.menus = {
          ...errors.menus,
          [key]: requiredMessage,
        };
      }
    });

    return errors;
  };

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
    errors,
    touched,
    values,
  } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    validate: onValidate,
    onSubmit,
  });

  const menuOptions = useMemo(
    () => getOptions(menusData?.menus.edges),
    [menusData?.menus.edges]
  );

  const weeks: Week[] = useMemo(() => {
    return buildWeeks(values.menus);
  }, [values.menus]);

  const getError = (name: string) => {
    return getErrorHelper<DietPlanSchema>(name, errors, touched);
  };

  const addMenusForNewWeek = () => {
    const menuQtd = Object.keys(values.menus).length;

    setFieldValue('menus', {
      ...values.menus,
      [menuQtd + 1]: null,
      [menuQtd + 2]: null,
    });
  };

  const removeMenusOfWeek = (week: Week) => {
    const newMenus = { ...values.menus };
    delete newMenus[week.firstDelivery.sequenceNumber];
    delete newMenus[week.secondDelivery.sequenceNumber];

    setFieldValue('menus', newMenus);
  };

  const getOption = (id?: string) => {
    return menuOptions.find((option) => option.id === id) || null;
  };

  const renderWeek = (week: Week, index: number) => {
    const firstDeliveryKey = `menus.${week.firstDelivery.sequenceNumber}`;
    const secondDeliveryKey = `menus.${week.secondDelivery.sequenceNumber}`;
    const isRemoveWeekDisabled = index === 0 || index !== weeks.length - 1;

    return (
      <Box display="flex" sx={{ columnGap: 2 }} key={index}>
        <Autocomplete
          title={week.firstDelivery.label}
          options={menuOptions}
          value={getOption(week.firstDelivery.menu?.id)}
          loading={menusLoading}
          placeholder="Select Menu"
          onChange={(_event, value) => {
            setFieldValue(firstDeliveryKey, { id: value?.id });
          }}
          onBlur={() => setFieldTouched(firstDeliveryKey)}
          error={!!getError(firstDeliveryKey)}
          helperText={getError(firstDeliveryKey)}
        />
        <Autocomplete
          title={week.secondDelivery.label}
          options={menuOptions}
          value={getOption(week.secondDelivery.menu?.id)}
          loading={menusLoading}
          placeholder="Select Menu"
          onChange={(_event, value) => {
            setFieldValue(secondDeliveryKey, { id: value?.id });
          }}
          onBlur={() => setFieldTouched(secondDeliveryKey)}
          error={!!getError(secondDeliveryKey)}
          helperText={getError(secondDeliveryKey)}
        />
        {showWeekManagement && (
          <Box mt="38px">
            <IconButton
              onClick={() => removeMenusOfWeek(week)}
              disabled={isRemoveWeekDisabled}
            >
              <Icon
                name="minus-circle-outline"
                fill={
                  isRemoveWeekDisabled
                    ? theme.palette.action.disabledBackground
                    : theme.palette.primary.main
                }
                size="large"
              />
            </IconButton>
          </Box>
        )}
      </Box>
    );
  };

  const shouldDisabledDate = (day: Date) => {
    return !isSunday(day);
  };

  if (loadingDietPlans) {
    return <Loader />;
  }

  return (
    <Card>
      <CardContent>
        <Box>
          <Grid container rowSpacing={3} columnSpacing={2}>
            <Grid item xs={8}>
              <TextField
                title="Name"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                error={!!getError('name')}
                helperText={getError('name')}
              />
            </Grid>
            <Grid item xs={4}>
              <DatePicker
                title="Starting Date"
                disabled={isEditMode}
                disablePast
                onChange={(value) => {
                  setFieldValue('initialDate', value);
                }}
                onBlur={() => setFieldTouched('initialDate')}
                shouldDisableDate={shouldDisabledDate}
                value={values.initialDate || null}
                error={!!getError('initialDate')}
                helperText={getError('initialDate')}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ my: 1 }} />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                title="Quick Ship Menu"
                options={menuOptions}
                value={getOption(values.quickShipMenu)}
                loading={menusLoading}
                placeholder="Select Quick Ship Menu"
                onChange={(_event, value) => {
                  setFieldValue('quickShipMenu', value?.id);
                }}
                onBlur={() => setFieldTouched('quickShipMenu')}
              />
            </Grid>
            <Grid item xs={12} display="flex" flexDirection="column" rowGap={3}>
              {weeks.map((week, index) => renderWeek(week, index))}
            </Grid>
          </Grid>
          {showWeekManagement && (
            <Button
              sx={{ mt: 5 }}
              startEvaIcon={{ name: 'plus-outline' }}
              onClick={addMenusForNewWeek}
            >
              Add new week
            </Button>
          )}
          <Divider sx={{ mt: 5 }} />
          <Box
            display="flex"
            justifyContent="flex-end"
            columnGap={1}
            marginTop={4}
          >
            <Button color="primary" size="large" onClick={onCancel}>
              CANCEL
            </Button>
            <Button
              disabled={isSubmitting}
              variant="contained"
              color="secondary"
              size="large"
              type="submit"
              onClick={() => handleSubmit()}
            >
              {isEditMode ? 'Edit' : 'Create'} Diet Plan
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default AddOrEditDietPlan;
